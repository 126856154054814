import { Link, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import { Helmet } from "react-helmet"

export default function About({ data }) {
  return (
    <main>
      <Helmet>
        <title>About</title>
      </Helmet>
      <section className="container">
        <div className="pt-6 pb-4 sm:pb-8 space-y-3 md:space-y-5">
          <h1>About</h1>
          <p className="text-lg text-zinc-500">
            Hello! I'm an avid learner and chronic builder (you can see some
            things I'm working on over on the{" "}
            <Link to="/projects">Projects page</Link>).
          </p>
          <GatsbyImage
            alt="Kyle Gill portrait"
            image={data.aboutImg.childImageSharp.gatsbyImageData}
            className="rounded-2xl overflow-hidden "
          />
          <div className="prose max-w-full">
            <p>
              I particularly appreciate the sweet spot in software where
              engineering and design meet. Things that look good and are built
              well are most appealing to me (and are what I most enjoy
              building).
            </p>
            <h2>Career</h2>
            <p>
              Right now I'm an <b>Engineer at Particl</b>, an e-commerce data
              company. I worked at a music tech company called Q&A, and an open
              source software company called Gatsby before that. In the past
              I've been a web developer, graphic designer, and a janitor.
            </p>
            <h2>Tools/technologies I'm grateful for</h2>
            <p>
              Building software is fun thanks to thousands of unpaid volunteer
              hours by a bunch of really smart people. I use these things on a
              day to day basis:
            </p>
            <ul className="grid gap-2 grid-cols-2">
              <li>JavaScript</li>
              <li>Python</li>
              <li>Elixir</li>
              <li>React</li>
              <li>Gatsby</li>
              <li>Node.js</li>
              <li>Ruby on Rails</li>
              <li>GraphQL</li>
              <li>Figma</li>
              <li>git</li>
            </ul>
          </div>
        </div>
      </section>
    </main>
  )
}

export const query = graphql`
  {
    aboutImg: file(relativePath: { eq: "portrait-landscape.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
      }
    }
  }
`
